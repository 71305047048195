import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/deferred-and-promise-in-jquery",
  "date": "2014-03-21",
  "title": "DEFERRED AND PROMISE IN JQUERY",
  "author": "admin",
  "tags": ["development", "javascript", "jquery"],
  "featuredImage": "feature.jpg",
  "excerpt": "Deferred and promise are part of jQuery since version 1.5 and they help in handling asynchronous functions like Ajax."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Long before Facebook and Xbox, If you wanted to catch a mouseclick, you did it with `}<inlineCode parentName="p">{`element.onclick = someFunction;`}</inlineCode>{` This became a problem when another part of the code also wanted to listen to this click. This was not possible, because you could only assign one function. This was solved at the time with the addEventListener DOM function. With this, you can add as many listener functions as you want. Since then, that’s the way we know how to do this.`}</p>
    <p>{`Now we have a similar problem with Ajax calls. This time it’s not the events, but the fact that Ajax supports only one callback function. Not only the jQuery $.ajax() call, but also the underlying XMLHttpRequest object.`}</p>
    <h2>{`Promise`}</h2>
    <p>{`Until jQuery 1.5, a typical $.ajax() call looked like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$.ajax({
  url: "/myServerScript",
  success: mySuccessFunction,
  error: myErrorFunction
});
`}</code></pre>
    <p>{`The $.ajax() call returns a jQuery XMLHttpRequest object. Nothing new so far.`}</p>
    <p>{`Since version 1.5, the returned object implements the CommonJS Promises/A interface. That’s a mouth full. CommonJS is a initiative to define common and independent interfaces (API’s). Promises/A is one such interface. The advantage is that these are not jQuery specific. For example, if you work with Node.js, there is a good chance you’ll program with this same interface. That’s good.`}</p>
    <p>{`The way of assigning callbacks with Promises is quite different:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var promise = $.ajax({
  url: "/myServerScript"
});

promise.done(mySuccessFunction);
promise.fail(myErrorFunction);
`}</code></pre>
    <p>{`You can combine the done() and fail() functions in one then() function. The code above can be rewritten as:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var promise = $.ajax({
  url: "/myServerScript"
});

promise.then(mySuccessFunction, myErrorFunction);
`}</code></pre>
    <p><em parentName="p">{`Okay, the interface has changed, so what’s in it for me?`}</em>{`, you might ask.`}</p>
    <p>{`The advantages of promises are:`}</p>
    <p>{`1) You can call the done() and fail() functions more times, with different callbacks. Maybe you have a callback function that stops an animation, one that does a new Ajax call and another function that shows the received data to the visitor.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var promise = $.ajax({
  url: "/myServerScript"
});

promise.done(myStopAnimationFunction);
promise.done(myOtherAjaxFunction);
promise.done(myShowInfoFunction);
promise.fail(myErrorFunction);
`}</code></pre>
    <p>{`2) Even after the Ajax call has finished, you can still call the done() and fail() functions and the callbacks are executed immediately. So no messing around with variables holding different states. When an Ajax call has finished, it will end up in either the success state or the failed state and this state will not change.`}</p>
    <p>{`3) You can combine promises. Sometimes you need to do two simultaneous Ajax calls and you want to execute a function when both are successfully finished. To do this, you use the new $.when() function.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var promise1 = $.ajax("/myServerScript1");
var promise2 = $.ajax("/myServerScript2");

$.when(promise1, promise2).done(function(xhrObject1, xhrObject2) {
  // Handle both XHR objects
});
`}</code></pre>
    <p>{`In contrast to what you might expect, the callback functions don’t get the received data as arguments, but the XHR objects. For Ajax requests this is very useful, but if you want to use $.when for other asynchronous code, it will be much harder.`}</p>
    <p>{`4) Since jQuery 1.8, you can chain the then() function sequentially. In the code below, first promise1 is run and when resolved successfully, getStuff is run, returning a promise and when this is resolved successfully, the anonymous function is executed.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var promise1 = $.ajax("/myServerScript1");

function getStuff() {
    return $.ajax("/myServerScript2");
}

promise1.then(getStuff).then(function(myServerScript2Data){
  // Both promises are resolved
});
`}</code></pre>
    <p>{`Every callback function receives the result of the previous asynchronous function, in the case of Ajax, that would be the returned data.`}</p>
    <h2>{`Deferred`}</h2>
    <p>{`So what is a deferred and what is the difference with a promise? As you have seen above, a promise is an object that is returned from an asynchronous function. You need a deferred when you write such a function yourself.`}</p>
    <p>{`A deferred object can do the same as a promise object, but it has two functions to trigger the done() and fail() functions.`}</p>
    <p>{`A deferred object has a resolve() functions for a successful result and to execute the functions assigned with done(). The reject() function is for a failed result and executes the functions assigned with fail().`}</p>
    <p>{`You can give parameters to both the resolve() and reject() functions and they will be passed on to the functions registered with done() and fail().`}</p>
    <p>{`The promise object does not have resolve() or reject() functions. This is because you give the promise away to other scripts and you don’t want them to resolve or reject the promise.`}</p>
    <p>{`Below is a simple script that illustrates how it works. The html is just an empty div with id `}<strong parentName="p">{`result`}</strong>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`$('#result').html('waiting...');

var promise = wait();
promise.done(result);

function result() {
  $('#result').html('done');
}

function wait() {
  var deferred = $.Deferred();

  setTimeout(function() {
    deferred.resolve();
  }, 2000);

  return deferred.promise();
}
`}</code></pre>
    <p>{`You can also find this script on `}<a parentName="p" {...{
        "href": "http://jsfiddle.net/TT3G5/"
      }}>{`jsFiddle`}</a>{`, so that you can experiment with it yourself.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`wait()`}</inlineCode>{` function is the function returning a promise. This will be resolved with a setTimeout of two seconds. Instead of setTimeout, everything can be used that is asynchronous, like animations, Web workers etcetera. It should be clear that inside the wait() function, we use the deferred object, but we return the limited promise object.`}</p>
    <h2>{`Criticism`}</h2>
    <p>{`Domenic Denicola wrote the article `}<a parentName="p" {...{
        "href": "https://gist.github.com/3889970"
      }}>{`You’re Missing the Point of Promises`}</a>{` in which he criticizes jQuery’s implementation of Promises. `}</p>
    <p>{`Take for example the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`promise.then(fn1).then(fn2).then(fn3, fail);
`}</code></pre>
    <p>{`If an error is thrown in fn1, the fail function should be called. This is a very nice way to handle errors in asynchronous functions. This does not work in jQuery and the writer sees this as a major shortcoming of jQuery.`}</p>
    <p>{`If you want to use `}<strong parentName="p">{`pure`}</strong>{` Promises/A, there are several JavaScript libraries to choose from. For example `}<a parentName="p" {...{
        "href": "http://documentup.com/kriskowal/q/"
      }}>{`Q`}</a>{` by Kristopher Kowal.`}</p>
    <p>{`This is an article about deferred and promise in jQuery.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      